import { BookingStatus } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import StaffBuilder from './mockStaff';
import { mockService } from './mockService';
import AllowedActionsBuilder from './mockAllowedActions';
import { BookingDTO, VideoConference, StaffDTO } from '../../src/types';

function getTimestamp(dateInstance = new Date()) {
  return dateInstance.getTime();
}

const { id: serviceId } = mockService();
const { id: staffId, fullName } = StaffBuilder().build();

const validBookingDTO = (): BookingDTO => {
  return {
    allowedActions: AllowedActionsBuilder().build(),
    bookingId: '123-456-789',
    contactId: '234-2343-2343',
    end: 0,
    groupInstanceId: '',
    locationInfo: 'my place',
    numOfParticipants: 0,
    service: mockService({ id: serviceId }),
    payed: 50,
    parentOfferingId: '',
    start: getTimestamp(new Date()),
    pricingPlanName: null,
    bookedAs: 'ONE_TIME',
    status: BookingStatus.CONFIRMED,
    staffInfo: {
      fullName,
      id: staffId,
    },
  };
};

function getRandomId(): string {
  const rand: number = Math.random() * 100000;
  return Math.round(rand).toString();
}

export default function BookingBuilder() {
  let bookingDTO = validBookingDTO();

  return {
    build: ({
      bookingID = getRandomId(),
    }: {
      bookingID?: string;
    } = {}) => {
      return {
        ...bookingDTO,
        bookingId: bookingID,
      };
    },
    withVideoConference(videoConference: VideoConference) {
      bookingDTO.videoConference = videoConference;
      return this;
    },
    withStatus(status: BookingStatus) {
      bookingDTO.status = status;
      return this;
    },
    withService(service = mockService()) {
      bookingDTO.service = service;
      return this;
    },
    withStaffInfo(saff?: StaffDTO) {
      const staffInfo = saff || StaffBuilder().build();
      bookingDTO.staffInfo = staffInfo;
      return this;
    },
    withRescheduleAllowed() {
      bookingDTO.allowedActions.reschedule = true;
      return this;
    },
    withRescheduleNotAllowed() {
      bookingDTO.allowedActions.reschedule = false;
      return this;
    },
    withCancelAllowed() {
      bookingDTO.allowedActions.cancel = true;
      return this;
    },
    withPricingPlanName(name: string | null) {
      bookingDTO.pricingPlanName = name;
      bookingDTO.bookedAs = 'PRICING_PLAN';
      return this.withRescheduleNotAllowed();
    },
    withCancelNotAllowed() {
      bookingDTO.allowedActions.cancel = false;
      return this;
    },
    withBookAnotherAllowed() {
      bookingDTO.allowedActions.bookAnother = true;
      return this;
    },
    withBookAnotherNotAllowed() {
      bookingDTO.allowedActions.bookAnother = false;
      return this;
    },
    withGroupService() {
      bookingDTO.service = mockService({ type: ServiceType.CLASS });
      return this;
    },
    withIndividualService() {
      bookingDTO.service = mockService({ type: ServiceType.APPOINTMENT });
      return this;
    },
    withCourseService() {
      bookingDTO.service = mockService({ type: ServiceType.COURSE });
      return this;
    },
    withLocationInfo(location: string) {
      bookingDTO.locationInfo = location;
      return this;
    },
    withStart(start = new Date()) {
      bookingDTO.start = getTimestamp(start);
      return this;
    },
    withEnd(end = new Date()) {
      bookingDTO.end = getTimestamp(end);
      return this;
    },
    withRevision(revision?: any) {
      bookingDTO.revision = revision;
      return this;
    },
    withNumberOfParticipants(numOfParticipants: number) {
      bookingDTO.numOfParticipants = numOfParticipants;
      return this;
    },
    withDynamicPriceInfo(dynamicPriceInfo?: string) {
      bookingDTO.dynamicPriceInfo = dynamicPriceInfo;
      return this;
    },
    withIsDynamicPrice(isDynamicPrice: boolean) {
      bookingDTO.isDynamicPrice = isDynamicPrice;
      return this;
    },
    withGroupId(groupId: string) {
      bookingDTO.groupId = groupId;
      return this;
    },
    withSessionId(sessionId: string) {
      bookingDTO.sessionId = sessionId;
      return this;
    },
    buildWith(obj?: Partial<BookingDTO>) {
      bookingDTO = { ...bookingDTO, ...obj };
      return this;
    },
  };
}
