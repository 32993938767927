import { CreateActionParams } from '../actions';
import { TabTypes } from '../../types';
import { EventNames } from '../../core/fedops';
import { logTabLoaded } from '../../core/bi/logTabLoaded';

export type GetUpcoming = () => void;

export function createGetUpcomingAction({
  context,
  getControllerState,
}: CreateActionParams): GetUpcoming {
  return async () => {
    const { api, fedopsLogger, biLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.UpcomingTabLoaded);

    const [state, setState] = getControllerState();

    const upcomingData = await api.getEnrichedBookings({
      type: TabTypes.UPCOMING,
      applicationState: state,
    });

    logTabLoaded(TabTypes.UPCOMING, upcomingData, biLogger);

    setState({
      upcomingData,
    });

    fedopsLogger?.interactionEnded(EventNames.UpcomingTabLoaded);
  };
}
