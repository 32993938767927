import { CreateActionParams } from '../actions';
import { TabTypes } from '../../types';
import { EventNames } from '../../core/fedops';
import { logTabLoaded } from '../../core/bi/logTabLoaded';

export type GetHistory = () => void;

export function createGetHistoryAction({
  context,
  getControllerState,
}: CreateActionParams): GetHistory {
  return async () => {
    const { api, fedopsLogger, biLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.HistoryTabLoaded);

    const [state, setState] = getControllerState();

    const historyData = await api.getEnrichedBookings({
      type: TabTypes.HISTORY,
      applicationState: state,
    });

    logTabLoaded(TabTypes.HISTORY, historyData, biLogger);

    setState({
      historyData,
    });

    fedopsLogger?.interactionEnded(EventNames.HistoryTabLoaded);
  };
}
