import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import { CreateActionParams } from '../actions';
import {
  BookingsGroup,
  CancelModalClickValues,
  MemberAreaClickActions,
  RescheduleButtonOrigin,
} from '../../types';
import { bookingsLoginUserAccountSeeAllServicesClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { logCancelBi } from '../../core/bi/logCancelBi';

export type ClickOnReschedule = (args: {
  group: BookingsGroup;
  origin: RescheduleButtonOrigin;
}) => void;

export function createClickOnRescheduleAction({
  getControllerState,
  context,
}: CreateActionParams): ClickOnReschedule {
  return async ({ group, origin }) => {
    const { flowAPI, biLogger } = context;
    const { wixCodeApi, platformAPIs } = flowAPI.controllerConfig;
    const [state] = getControllerState();
    const slug = group.bookings[0].service?.mainSlug?.name;

    const selectedTab = state.selectedTab.type;

    if (origin === RescheduleButtonOrigin.ActionMenu) {
      biLogger?.report(
        bookingsLoginUserAccountSeeAllServicesClick({
          action: JSON.stringify({
            action: MemberAreaClickActions.Reschedule,
            bookingId: group.bookings[0].bookingId,
            serviceId: group.bookings[0].service?.id,
            contactId: group.bookings[0].contactId,
          }),
          referralInfo: selectedTab,
        }),
      );
    } else if (origin === RescheduleButtonOrigin.CancellationModal) {
      if (biLogger) {
        logCancelBi(biLogger, selectedTab, CancelModalClickValues.Reschedule);
      }
    }

    try {
      await navigateToBookingsCalendarPage(wixCodeApi, platformAPIs as any, {
        serviceSlugOrBasket: slug,
        navigationContext: {
          bookingId: group.bookings[0].bookingId,
        },
      });
    } catch {
      wixCodeApi.location.to?.('/');
    }
  };
}
