import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import { CreateActionParams } from '../actions';
import { BookingsGroup, MemberAreaClickActions } from '../../types';
import { bookingsLoginUserAccountSeeAllServicesClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';

export type ClickOnBookAgain = (args: { group: BookingsGroup }) => void;

export function createClickOnBookAgainAction({
  getControllerState,
  context,
}: CreateActionParams): ClickOnBookAgain {
  return async ({ group }) => {
    const { flowAPI, biLogger } = context;
    const { wixCodeApi, platformAPIs } = flowAPI.controllerConfig;
    const [state] = getControllerState();
    const slug = group.bookings[0].service?.mainSlug?.name;

    const serviceType =
      group.bookings[0].service?.type === ServiceType.APPOINTMENT
        ? 'ind'
        : 'class';

    biLogger?.report(
      bookingsLoginUserAccountSeeAllServicesClick({
        action: JSON.stringify({
          action: MemberAreaClickActions.BookAgain,
          type: serviceType,
        }),
        referralInfo: state.selectedTab.type,
      }),
    );

    try {
      await navigateToBookingsCalendarPage(wixCodeApi, platformAPIs as any, {
        serviceSlugOrBasket: slug,
      });
    } catch {
      wixCodeApi.location.to?.('/');
    }
  };
}
