import { ApplicationStatus } from '../../types';
import { CreateActionParams } from '../actions';
import { getBusinessInfo } from './implementations/getBusinessInfo';
import { getUserInfo } from './implementations/getUserInfo';
import { getSelectedTimezone } from './implementations/getSelectedtTmezone';
import { createUpcomingDummyState } from '../../utils/createDummyState';
import { EventNames } from '../../core/fedops';

export type InitializeWidget = () => void;

export function createInitializeWidgetAction({
  context,
  getControllerState,
}: CreateActionParams): InitializeWidget {
  return async () => {
    const { fedopsLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.WidgetInitialized);

    const [state, setState] = getControllerState();

    if (state.environmentModes.isEditorMode) {
      return setState(createUpcomingDummyState(context));
    }

    const [businessInfo, userInfo] = await Promise.all([
      getBusinessInfo(context),
      getUserInfo(context),
    ]);

    const selectedTimezone = getSelectedTimezone({ businessInfo, userInfo });

    setState({
      status: ApplicationStatus.IDLE,
      businessInfo,
      userInfo,
      selectedTimezone,
    });

    fedopsLogger?.interactionEnded(EventNames.WidgetInitialized);
  };
}
