import { queryExtendedBookings as bookingsQuery } from '@wix/ambassador-bookings-reader-v2-extended-booking/http';
import {
  QueryExtendedBookingResponse,
  SortOrder,
  BookingStatus,
  QueryExtendedBookingRequest,
} from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { ApiContext } from '../../types';

export interface QueryBookingsArgs {
  contactId: string;
  sortOrder?: SortOrder;
  endDateComparisonOperator?: '$gt' | '$lte';
  withBookingConferencingDetails?: boolean;
}

export const queryBookings = async ({
  flowAPI,
  sortOrder = SortOrder.ASC,
  endDateComparisonOperator = '$gt',
  contactId,
  withBookingConferencingDetails,
}: QueryBookingsArgs & ApiContext): Promise<QueryExtendedBookingResponse> => {
  const { httpClient } = flowAPI;

  const request: QueryExtendedBookingRequest = {
    query: {
      sort: [
        {
          order: sortOrder,
          fieldName: 'startDate',
        },
      ],
      paging: {
        offset: null,
        limit: 100,
      },
      filter: {
        endDate: {
          [endDateComparisonOperator]: new Date().toISOString(),
        },
        status: {
          $in: [
            BookingStatus.CONFIRMED,
            BookingStatus.PENDING,
            BookingStatus.WAITING_LIST,
          ],
        },
        ...(contactId ? { 'contactDetails.contactId': contactId } : {}),
      },
    },
    withBookingAllowedActions: true,
    withBookingConferencingDetails,
  };

  return (
    await httpClient.request<QueryExtendedBookingResponse>(
      bookingsQuery(request),
    )
  ).data;
};
